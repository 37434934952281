import React from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import YoutubeVideos from "../YoutubeVideos";

function Reviews() {
  const reviews = [
    {
      name: "chaitanya latha dasi",
      review:
      "I have visited Dr. Padmanabha varma's hospital in pragathi nagar. After visiting the doctor I actually came to know about my health problem which I was not aware of. The doctor was knowledgeable and made me understand my problem by explaining so clearly with patience and prescribed the tests and medication.I surely suggest this hospital for the ones who are not sure about their health issue. The staff were also so professional "
    },
    {
      name: "Anusha komuravelli",
      review:
      "Very good doctor, After speaking to the doctor I was completely came out with my myths and doctor suggested good diet to follow.I will follow doctor suggestions and He is listening our problems with patience and provided good suggestions. I will strongly recommend if anyone want good doctor."
    },
    {
      name: "todo mint",
      review:
      "Best doctor. Very simple and gentle in his behavior. In my every appointment with him I have been met with a smile, understanding of my concerns and compassion."
    },
    {
      name: "Chethan Chowdary",
      review:
      "Genuine doctor with lot of patience . He evaluate patient profile thoroughly. avoids unnecessary medications"
    },
    {
      name: "Sandeep Kiran Kaundinya",
      review:
      "Dr. Padmanabha Varma was the best Physician I had ever consulted. Knowledgeable, sensitive, informative… We immediately felt at ease  and confident in his expert medical advice. It’s rare to find a doctor that combines such personal touches and cares for a patient with outstanding concern. I highly recommend having him as a FAMILY DOCTOR that’s what we have done after our first consultation because of his very judicious prescription of medicines."
    },
    {
      name: "harini gudepu",
      review:
      "Best doctor in recent times. Listens with patience. Clears every doubt. Gives background of situation and solutions. Lively and friendly. Gives best solutions and medications. I trust Mr. Varma with my father's and my health."
    },
    {
      name: "vydula dheeraj",
      review:
      "Dr. Padmanabha Varma have much patience , he listen to the patient very well and understand the issue . He clearly explains the issue in layman language so that normal person can understand easily ."
    },
    {
      name: "divya reddy",
      review:
      "Dr Varma garu treatment is good , gives cost effective medication Explains what is what in simple and easy to understand way Happy with the service"
    },
    {
      name: "Ajaykumar Tummala",
      review:
      "Dr Varma is an excellent doctor. Spends a lot of time listening to the problems of the patients and suggests the right treatment regime."
    },
    {
      name: "TS Rao pune",
      review:
      "Have been able to get clarity on my health condition and the medicines to use.  Highly satisfied with the interaction."
    },
    {
      name: "Radhika",
      review:
      "We resently migrated to Hyderabad n we were looking out for Endocrinologist for thyroid problem. Researched in Google n found him near by. Called him in the evening n next day morning sharp 8am lab technicians came home to pick the sample. By evening met the Dr. Padmanabha Varma with reports. He analysed the report n gave us wonderful treatment n Suggestions. The clinic is in the prime location. Ambience n reception is very good. Thank you Doctor for your soothing words."
    },
    {
      name: "banda sreeramsarma",
      review:
      "He treated my father very well and he listens patiently...."
    },
    {
      name: "kaushik chakraborty",
      review:
      "Doctor is good. Took time listen our insights and suggested medication"
    },
    {
      name: "kanna areti",
      review:
      "Strongly recommend he will spend time and clearly discuss the issue with patients"
    },
    {
      name: "vikas Elakuchi",
      review:
        "One the best endocrinologist in Hyderabad. He diagnoses well and treats well"
    },
  ];

  return (
    <div>
      <div className="main-area">
        <div className="container">
          <div className="breadcrums py-1">
            <div className="row pt-4">
              <div className="col-auto col-md-10">
                <nav aria-label="breadcrumb " className="second">
                  <ol className="breadcrumb indigo lighten-6 first">
                    <li className="breadcrumb-item font-weight-bold ">
                      <a className="black-text text-uppercase bread_a" href="#">
                        <span className="mr-md-3 mr-2">Home</span>
                      </a>
                      <i
                        className="fa fa-angle-double-right "
                        aria-hidden="true"
                      />
                    </li>
                    <li className="breadcrumb-item font-weight-bold">
                      <a className="black-text text-uppercase bread_a" href="#">
                        <span className="mr-md-3 mr-2">Patient Guide</span>
                      </a>
                      <i
                        className="fa fa-angle-double-right text-uppercase "
                        aria-hidden="true"
                      />
                    </li>
                    <li className="breadcrumb-item font-weight-bold">
                      <a
                        className="black-text text-uppercase bread_a active-2"
                        href="#"
                      >
                        <span>Reviews & Videos</span>
                      </a>
                    </li>
                  </ol>
                </nav>
              </div>

              <h1 className="Title_header">Patients Reviews & Videos</h1>
            </div>
          </div>
        </div>

        <div className="container-fluid px-0">
          <div className="container">
            <Tabs
              defaultActiveKey="home"
              id="uncontrolled-tab-example"
              className="mb-3"
            >
              <Tab eventKey="home" title="Reviews">
                <div id="Reviews" className="review_bg">
                  <div className="mt-4 p-4">
                    <div className="gview">
                      <h3>Google Reviews</h3>
                    </div>

                    <div className="row">
                      {reviews.map((review, index) => {
                        return (
                          <div className="col-md-4 mb-4" key={index}>
                            <div className="card min335 p-3">
                              <div className="stars">
                                <div className="start_cls">
                                  <i className="fa fa-star text-warning1 mx-1 fx-2"></i>
                                  <i className="fa fa-star text-warning1 mx-1 fx-2"></i>
                                  <i className="fa fa-star text-warning1 mx-1 fx-2"></i>
                                  <i className="fa fa-star text-warning1 mx-1 fx-2"></i>
                                  <i className="fa fa-star text-warning1 mx-1 fx-2"></i>
                                </div>

                                <div className="user">
                                  <h5>{review.name}</h5>
                                </div>
                              </div>

                              <div className="customerimg my-3">
                                <p>{review.review}</p>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </Tab>
            </Tabs>

            <div className="bookappointment my-4">
              <div class="row">
                <div className="col-md-8">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15219.318009617673!2d78.396404!3d17.5156505!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb91530ac9ecf7%3A0x1946e7ee6bcc884d!2sDr.%20Padmanabha%20Varma&#39;s%20Hormone%20Clinic%20-%20Diabetes%20%2CThyroid%20%26%20Endocrine%20Super%20Speciality%20Centre!5e0!3m2!1sen!2sin!4v1691808841355!5m2!1sen!2sin"
                    width="750"
                    height="450"
                    style={{ border: "0" }}
                    allowFullScreen
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"
                  />
                </div>

                <div className="col-md-4">
                  <div className="formSubmit">
                    <p>We Are Ready To Help You With A Smile!</p>
                    <h4 className="font-weight-bold">
                      <i class="fa fa-cell"></i>Call Us:{" "}
                      <span className="colorora">+91 9550 899 143</span>
                    </h4>

                    <form>
                      <input
                        type="text"
                        placeholder="Fullname*"
                        className="form-control mb-3"
                      />

                      <input
                        type="text"
                        placeholder="Mobile*"
                        className="form-control mb-3"
                      />

                      <input
                        type="text"
                        placeholder="Email*"
                        className="form-control mb-3"
                      />

                      <select className="form-control mb-3">
                        <option value="Diabetes">Diabetes</option>
                        <option value="Thyroid">Thyroid</option>
                        <option value="Adrenal">Adrenal</option>
                        <option value="Pituitary">Pituitary</option>
                        <option value="Gonaos">Gonaos</option>
                        <option value="VitaminD & Calcium">
                          VitaminD & Calcium
                        </option>
                        <option value="Growth">Growth</option>
                        <option value="Obesity">Obesity</option>
                      </select>
                      <div className="my-3">
                        <textarea
                          placeholder="Please tell us your conern in detail"
                          className="form-control my-3"
                        ></textarea>
                      </div>
                    </form>

                    <div className="my-3">
                      <a href="" className="bookapp my-4 w-100">
                        Book Appointment
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Reviews;
