import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { useEffect } from "react";
import PCod from "../../assets/Gyanecology/PCOD.jpg";
import $ from "jquery";
import Gynaecology from "../../assets/Gyanecology/Gynaecology.jpg";
import Endometriosis from "../../assets/Gyanecology/Endometriosis.jpg";
import Menstrual from "../../assets/Gyanecology/Menstrual-Problems.jpg";
import Blog1 from "../../assets/Gyanecology/blog1.png";
import Blog2 from "../../assets/Gyanecology/blog2.png";
import Blog3 from "../../assets/Gyanecology/blog3.png";
import Madampic from "../../assets/madampic.png";
import Shieldpic from "../../assets/sheildpic.png";
import Cervicalcancer1 from "../../assets/all/Cervical-Cancer.jpg";
import axios from "axios";
import { useState } from "react";
import Parser from "html-react-parser";
import { useLocation } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import loader from "../../assets/loader/loader.gif";
import YoutubeVideos from "../YoutubeVideos";

function Dynamic_page() {
  const [isLoading, setIsLoading] = useState(false);
  const [page_data, setPage_data] = useState();

  // submit contact

  const [loadingBook, setLoadingBook] = useState(false);
  const [formData, setFormData] = useState({});

  const [emptyData, setEmptyData] = useState({
    fullname: "",
    email: "",
    mobile: "",
    message: "",
    reason: "",
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoadingBook(true);
    axios
      .post(
        "https://admin.drpadmanabhavarma.com/api/v1/contactpage",
        formData
      )
      .then((res) => {
        toast.success(res.data.message);
        setFormData(emptyData);
        setLoadingBook(false);
      })
      .catch((err) => {
        setLoadingBook(false);
        if (err.response.data.status === 500) {
          toast.error("Server Error");
        }
        if (
          err.response.data.status === 400 ||
          err.response.data.status === 401
        ) {
          if (typeof err.response.data.message === "object") {
            for (var key in err.response.data.message) {
              toast.error(err.response.data.message[key][0]);
            }
          } else {
            toast.error(err.response.data.message);
          }
        }
      });
  };
  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };

  // end submit contact

  var url = document.URL;
  const location = useLocation();
  var page_name = url.substr(url.lastIndexOf("/") + 1);
  useEffect(() => {
    setIsLoading(true);
    axios
      .get(
        "https://admin.drpadmanabhavarma.com/api/v1/dynamic_pages/" +
          page_name
      )
      .then(function (response) {
        setPage_data(response.data);
        setIsLoading(false);
      });

    $(document).ready(function () {
      $(".b_anchor").click(function () {
        $(".b_anchor").removeClass("b_active").addClass("inactive");
        $(this).removeClass("inactive").addClass("b_active");
      });

      $(".accordion-toggle").on("click", function (event) {
        event.preventDefault();
        // create accordion variables
        var accordion = $(this);
        var accordionContent = accordion.next(".accordion-content");

        // toggle accordion link open class
        accordion.toggleClass("open");
        // toggle accordion content
        accordionContent.slideToggle(250);
      });
    });
  }, [location]);

  if (page_data) {
    return (
      <div>
        <div className="main-area">
          <div className="container">
            <div className="breadcrums py-4">
              <div className="row pt-4">
                <div className="col-auto col-md-10">
                  <nav aria-label="breadcrumb " className="second">
                    <ol className="breadcrumb indigo lighten-6 first">
                      <li className="breadcrumb-item font-weight-bold ">
                        <a
                          className="black-text text-uppercase bread_a"
                          href="#"
                        >
                          <span className="mr-md-3 mr-2">Home</span>
                        </a>
                        <i
                          className="fa fa-angle-double-right "
                          aria-hidden="true"
                        />
                      </li>
                      <li className="breadcrumb-item font-weight-bold">
                        <a
                          className="black-text text-uppercase bread_a"
                          href="#"
                        >
                          <span className="mr-md-3 mr-2">
                            {page_data.title}
                          </span>
                        </a>
                        <i
                          className="fa fa-angle-double-right text-uppercase "
                          aria-hidden="true"
                        />
                      </li>
                      <li className="breadcrumb-item font-weight-bold">
                        <a
                          className="black-text text-uppercase bread_a active-2"
                          href="#"
                        >
                          <span>{page_data.title}</span>
                        </a>
                      </li>
                    </ol>
                  </nav>
                </div>

                <h1 className="Title_header">{page_data.title}</h1>
              </div>
            </div>
          </div>

          <div className="container-fluid px-0">
            <div className="border-bottom1 ">
              <div className="container">
                <div className="mainTabs">
                  <a href="#About" className="b_anchor inactive">
                    About
                  </a>
                  <a href="#Reviews" className="b_anchor inactive">
                    Reviews
                  </a>

                  {/* <a href="#Videos" className="b_anchor inactive">
                    Videos
                  </a> */}
                  <a href="#QA" className="b_anchor inactive">
                    Q&A's
                  </a>
                  <a href="#drpadmanabhavarma" className="b_anchor inactive">
                    Dr. Padmanabha Varma
                  </a>
                </div>

                <div class="TabsContent">
                  <div id="About">
                    <div className="mt-4">
                      <div className="diseas_about">
                        <div class="row">
                          <div className="tabs_New">
                            {Parser(page_data.content)}
                            {page_data.images && page_data.images !== "none" ? (
  <img src={"/" + page_data.images} alt="Image" />
) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div id="Reviews" className="review_bg">
                    <div className="mt-4 p-4">
                      <div className="gview">
                        <h3>Customer Reviews</h3>
                      </div>

                      <div className="row">
                        <div className="col-md-4">
                          <div className="card min335 p-3">
                            <div className="stars">
                              <div className="start_cls">
                                <i className="fa fa-star text-warning1 mx-1 fx-2"></i>
                                <i className="fa fa-star text-warning1 mx-1 fx-2"></i>
                                <i className="fa fa-star text-warning1 mx-1 fx-2"></i>
                                <i className="fa fa-star text-warning1 mx-1 fx-2"></i>
                                <i className="fa fa-star text-warning1 mx-1 fx-2"></i>
                              </div>

                              <div className="user">
                                <h5>Gayatri sakhi</h5>
                              </div>
                            </div>

                            <div className="customerimg my-3">
                              <p>
                              I recently visited the clinic of Dr. Padmanabha Varma's Harmone India.</p>
                              <p>During my appointment, Doctor took the time to listen attentively to my medical concerns and asked thoughtful questions to understand my medical history thoroughly.</p>
                              <p> He explained the medical  situation in a clear and understandable manner, making sure I was well-informed about my condition and suggested medication.</p>

                              <p>The staff at the Clinic was friendly and professional.</p>
                              <p>I would recommend Dr.Padmanabha Varma's clinic for sure to set free any medical concerns and to have tension free step out from clinic to your home..</p>

                              <p>Best Doctor in the recent times..                              </p>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="card min335 p-3">
                            <div className="stars">
                              <div className="start_cls">
                                <i className="fa fa-star text-warning1 mx-1 fx-2"></i>
                                <i className="fa fa-star text-warning1 mx-1 fx-2"></i>
                                <i className="fa fa-star text-warning1 mx-1 fx-2"></i>
                                <i className="fa fa-star text-warning1 mx-1 fx-2"></i>
                                <i className="fa fa-star text-warning1 mx-1 fx-2"></i>
                              </div>

                              <div className="user">
                                <h5>Rajesh Goruntula</h5>
                              </div>
                            </div>

                            <div className="customerimg my-3">
                              <p>
                              I visited the clinic of Dr. Padmanabha Varma's Harmone India for Thyroid and Diabetes.</p>
                              <p>During checkup, Doctor took the time to listen attentively and very patience to my medical concerns and asked thoughtful questions to understand my medical history thoroughly. He explained the medical situation in a clear and understandable manner, making sure I was well-informed about my condition and suggested medication.Also doctor suggests and prescribes medicines as required only won't prescribes unwantedly.</p>

                              <p>The staff at the Clinic was friendly and professional.20% discount given in medicines and lab tests too.</p>
                              <p>I would recommend Dr.Padmanabha Varma's clinic for sure to set free any medical concerns and to have tension free step out from clinic to your home..                              </p>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="card min335 p-3">
                            <div className="stars">
                              <div className="start_cls">
                                <i className="fa fa-star text-warning1 mx-1 fx-2"></i>
                                <i className="fa fa-star text-warning1 mx-1 fx-2"></i>
                                <i className="fa fa-star text-warning1 mx-1 fx-2"></i>
                                <i className="fa fa-star text-warning1 mx-1 fx-2"></i>
                                <i className="fa fa-star text-warning1 mx-1 fx-2"></i>
                              </div>

                              <div className="user">
                                <h5>kalyani kalyani</h5>
                              </div>
                            </div>

                            <div className="customerimg my-3">
                              <p>
                              Very good doctor. He has very much patience. Understands our problems nd guides in a correct way. Spents a lot of time with every individual patient.</p>
                              <p>Now a days it's very rare that doctors listening to patients for more than 5 min..  </p>
                              <p>But i doesn't happens in his clinic. Best doctor ..                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  {/* <div id="Videos">
                    <div className="mt-4 p-4">
                      <div className="gview">
                        <h3>Our Treatment Videos</h3>
                      </div>
                    </div>
                    <YoutubeVideos />
                  </div> */}

                  <div id="drpadmanabhavarma">
                    <div className="mt-4 bgone">
                      <h2 className="font-weight-bold px-4 py-2 text-dark">
                        About Dr. Padmanabha Varma
                      </h2>
                      <div class="ddam-flex">
                        <div className="flexdear">
                          <div className="madampic">
                            <img
                              src={Madampic}
                              alt="Dr. Padmanabha Varma"
                              className="madampicsize"
                            />
                          </div>

                          <div className="madamdetails pmsdy-4">
                            <h4 class="nameDr">Dr. Padmanabha Varma</h4>
                            <p>Diabetes and endocrine specialist</p>

                            <div className="ratings my-3">
                              <div className="starrate">
                                <i className="fa fa-star starsize"></i>
                                <span className="fontwave">5</span>
                              </div>
                              <div className="mr-4">
                                <img src={Shieldpic} />
                                <span className="font-weight-bold">
                                  {" "}
                                  Verified & Most Trusted One
                                </span>
                              </div>
                            </div>

                            <p>
                              <span className="font-weight-bold">
                                Dr. Padmanabha Varma
                              </span>
                              is a well-known name in the field of
                              endocrinology. He is trained in one of the best
                              institutes in India and worked under reputed
                              doctors in the field of endocrinology. He has over
                              10 years of experience in treating all types of
                              diabetes patients and complex hormonal problems.
                              He has earned many gold medals, delivered lectures
                              across India. He regularly conducted free diabetes
                              and thyroid camps for people of rural background{" "}
                            </p>

                            {/* <div className="knowmore my-4">
                                                            <a href="" className="knowmore_cls">
                                                                Know more
                                                            </a>
                                                        </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="bookappointment my-4">
                    <div class="row">
                      <div className="col-md-8">
                        <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15219.318009617673!2d78.396404!3d17.5156505!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb91530ac9ecf7%3A0x1946e7ee6bcc884d!2sDr.%20Padmanabha%20Varma&#39;s%20Hormone%20Clinic%20-%20Diabetes%20%2CThyroid%20%26%20Endocrine%20Super%20Speciality%20Centre!5e0!3m2!1sen!2sin!4v1691808841355!5m2!1sen!2sin"
                  width="750"
                          height="550"
                          style={{ border: "0" }}
                          allowFullScreen
                          loading="lazy"
                          referrerpolicy="no-referrer-when-downgrade"
                        />
                      </div>

                      <div className="col-md-4">
                        <div className="formSubmit">
                          <p>We Are Ready To Help You With A Smile!</p>
                          <h4 className="font-weight-bold">
                            <i class="fa fa-cell"></i>Call Us:{" "}
                            <span className="colorora">+91 9550 899 143</span>
                          </h4>

                          <form onSubmit={handleSubmit}>
                            <input
                              type="text"
                              placeholder="Fullname*"
                              name="fullname"
                              id="fullname"
                              value={formData ? formData.fullname : ""}
                              onChange={handleChange}
                              className="form-control mb-3"
                            />

                            <input
                              type="number"
                              placeholder="Mobile*"
                              name="mobile"
                              id="mobile"
                              value={formData ? formData.mobile : ""}
                              onChange={handleChange}
                              className="form-control mb-3"
                            />

                            <input
                              type="email"
                              placeholder="Email*"
                              name="email"
                              id="email"
                              value={formData ? formData.email : ""}
                              onChange={handleChange}
                              className="form-control mb-3"
                            />

                            <select
                              className="form-control mb-3"
                              name="reason"
                              id="reason"
                              onChange={handleChange}
                              value={formData ? formData.reason : ""}
                            >
                              <option>--Select Reason--</option>

                              <option value="Diabetes">Diabetes</option>
                              <option value="Thyroid">Thyroid</option>
                              <option value="Adrenal">Adrenal</option>
                              <option value="Pituitary">Pituitary</option>
                              <option value="Gonaos">Gonaos</option>
                              <option value="VitaminD & Calcium">
                                VitaminD & Calcium
                              </option>
                              <option value="Growth">Growth</option>
                              <option value="Obesity">Obesity</option>
                            </select>
                            <div className="my-3">
                              <textarea
                                placeholder="Please tell us your concern in detail *"
                                className="form-control my-3"
                                name="message"
                                id="message"
                                value={formData ? formData.message : ""}
                                onChange={handleChange}
                              ></textarea>
                            </div>

                            <div className="gapivvu text-center">
                              {loadingBook ? (
                                <img src={loader} style={{ height: "100px" }} />
                              ) : (
                                <button
                                  type="submit"
                                  className="bookapp  w-100"
                                >
                                  Book Appointment
                                </button>
                              )}
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return <span>Loading</span>;
  }
}
export default Dynamic_page;
