import React, { useState } from "react";
import Madampic from "../../assets/madampic.png";
import Shieldpic from "../../assets/sheildpic.png";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Contact() {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/Aboutdr");
  };

  // contact us
  const [emptyData, setEmptyData] = useState({
    name: "",
    email: "",
    mobile: "",
    reason: "",
    message: "",
  });
  const [formData, setFormData] = useState({});

  const handleSubmit = (event) => {
    event.preventDefault();

    axios
      .post(
        "https://admin.drpadmanabhavarma.com/api/v1/contactpage",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        toast.success(res.data.message);
        setFormData(emptyData);
      })
      .catch((err) => {
        if (err.response.data.status === 500) {
          toast.error("Server Error");
        }
        if (
          err.response.data.status === 400 ||
          err.response.data.status === 401
        ) {
          if (typeof err.response.data.message === "object") {
            for (var key in err.response.data.message) {
              toast.error(err.response.data.message[key][0]);
            }
          } else {
            toast.error(err.response.data.message);
          }
        }
      });
  };
  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };



  return (
    <div>
      <div id="drpadmanabhavarma">
        <div className="container">
        

          <div className="bookappointment my-4">
            <div class="row">
              <h2 className="font-weight-bold px-4 py-2 text-dark text-center mb-3">
                Contact us
              </h2>
              <div className="col-md-8">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15219.318009617673!2d78.396404!3d17.5156505!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb91530ac9ecf7%3A0x1946e7ee6bcc884d!2sDr.%20Padmanabha%20Varma&#39;s%20Hormone%20Clinic%20-%20Diabetes%20%2CThyroid%20%26%20Endocrine%20Super%20Speciality%20Centre!5e0!3m2!1sen!2sin!4v1691808841355!5m2!1sen!2sin"
                  width="600"
                  height="450"
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
              <div className="col-md-4">
                <div className="formSubmit">
                  <p>We Are Ready To Help You With A Smile!</p>
                  <h4 className="font-weight-bold">
                    <i class="fa fa-cell"></i>Call Us:{" "}
                    <span className="colorora">+91 9550 899 143</span>
                  </h4>

                  <form onSubmit={handleSubmit}>
                    <input
                      type="text"
                      placeholder="Fullname*"
                      name="fullname"
                      id="fullname"
                      value={formData ? formData.name : ""}
                      onChange={handleChange}
                      className="form-control mb-3"
                    />

                    <input
                      type="text"
                      placeholder="Mobile*"
                      value={formData ? formData.name : ""}
                      onChange={handleChange}
                      id="mobile"
                      name="mobile"
                      className="form-control mb-3"
                    />

                    <input
                      type="email"
                      placeholder="Email*"
                      name="email"
                      id="email"
                      value={formData ? formData.name : ""}
                      onChange={handleChange}
                      className="form-control mb-3"
                    />

                    <select
                      className="form-control mb-3"
                      id="reason"
                      name="reason"
                      value={formData ? formData.reason : ""}
                      onChange={handleChange}
                    >
                      <option>--select problem--</option>
                      <option value="Diabetes">Diabetes</option>
                      <option value="Thyroid">Thyroid</option>
                      <option value="Adrenal">Adrenal</option>
                      <option value="Pituitary">Pituitary</option>
                      <option value="Gonaos">Gonaos</option>
                      <option value="VitaminD & Calcium">
                        VitaminD & Calcium
                      </option>
                      <option value="Growth">Growth</option>
                      <option value="Obesity">Obesity</option>
                    </select>
                    <div className="my-3">
                      <textarea
                        id="message"
                        name="message"
                        value={formData ? formData.name : ""}
                        onChange={handleChange}
                        placeholder="Please tell us your conern in detail"
                        className="form-control my-3"
                      ></textarea>
                    </div>
                    <div className="my-3">
                      <button className="bookapp my-4 w-100">
                        Book Appointment
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
