import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap";
import "./App.css";
import Button from "react-bootstrap/Button";
import { Routes, Route, Link, useLocation } from "react-router-dom";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import Home from "./Components/Home";
import Contact from "./Components/Aboutus/Contact";
import Aboutclinic from "./Components/Aboutus/Aboutclinic";
import Aboutdr from "./Components/Aboutus/Aboutdr";
import Blogs from "./Components/PatientGuide/Blogs";
import Reviews from "./Components/PatientGuide/Reviews";
import Videos from "./Components/PatientGuide/Videos";
import ImageLightbox from "./Components/Images";
import "./Components/Navbar.css";
import Dynamic_page from "./Components/Dynamicpage/Dynamicpage";
import { useEffect, useState } from "react";

function openNav() {
  document.getElementById("mySidebar").style.width = "250px";
  document.getElementById("main").style.marginLeft = "250px";
}

function closeNav() {
  document.getElementById("mySidebar").style.width = "0";
  document.getElementById("main").style.marginLeft = "0";
}

function App() {
  const [isMobile, setIsMobile] = useState(false);
  //choose the screen size
  const handleResize = () => {
    if (window.innerWidth < 1200) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }; // create an event listener
  useEffect(() => {
    window.addEventListener("resize", handleResize);
  });

  const menu = [
    {
      navitem: "Diabetes",
      submenu: [
        { title: "Type 1 Diabetes Mellitus", link: "/page/type1" },
        { title: "Type 2 Diabetes Mellitus", link: "/page/type2" },
        {
          title: "Pancreatic Diabetes Mellitus",
          link: "/page/pancreatic_diabetes",
        },
        {
          title: "Monogenic Diabetes Mellitus",
          link: "/page/monogenic_diabetes",
        },
        {
          title: "Gestational Diabetes Mellitus",
          link: "/page/gestational_diabetes",
        },
        {
          title: "Secondary Diabetes Mellitus",
          link: "/page/secondary_diabetes	",
        },
        { title: "Insulin Pumps", link: "/page/insulin_pump" },
        {
          title: "Continous Glucose Monitoring Systems (CGMS)",
          link: "/page/continuous_glucose",
        },
        // { title: 'Monitoring System', link: '/page/monitoring_system' },
        {
          title: "Complications of Diabetes Mellitus",
          link: "/page/complications_of_diabetes_mellitus",
        },
        { title: "Hypoglycemia", link: "page/hypoglycemia" },
      ],
    },
    {
      navitem: "Thyroid",
      submenu: [
        { title: "Hypothyroidism", link: "/page/hypothyroidism" },
        { title: "Hyperthyroidism", link: "/page/hyperthyroidism" },
        { title: "Goiter", link: "/page/goiter_thyroid" },
        { title: "Thyroid Cancers", link: "/page/cancer" },
      ],
    },

    {
      navitem: "Adrenal",
      submenu: [
        {
          title: "Adrenal Insufficiency",
          link: "/page/adrenal_insufficiently",
        },
        { title: "Cushing Syndrome", link: "/page/cushing_syndrome" },
        { title: "Adrenal Cancer", link: "/page/adrenal_cancer_syndrome" },
        { title: "Hyperaldosteronism", link: "/page/hyperaldosteronism" },
        {
          title: "Secondary Hypertension	",
          link: "/page/secondary_hypertension",
        },
        { title: "Pheochromocytoma", link: "/page/pheochromocytoma" },
      ],
    },

    {
      navitem: "Pituitary",
      submenu: [
        { title: "Pituitary Tumors", link: "/page/pituitary_tumors" },
        { title: "Acromegaly", link: "/page/acromegaly" },
        { title: "Prolactinoma", link: "/page/prolactinoma" },
        { title: "Diabetes Insipidus", link: "/page/diabetes_insipidus" },
        {
          title: "Growth Hormone Deficiency",
          link: "/page/growth_hormone_deficiency",
        },
        { title: "Hypopituitarism ", link: "/page/hypopituitarism" },
        { title: "Sheehans Syndrome", link: "/page/sheehan_syndrome" },
      ],
    },
    {
      navitem: "Gonads",
      submenu: [
        { title: "PCOD/PCOS", link: "/page/PCOD" },
        { title: "Male Hypogonadism", link: "/page/male_hypogonadism" },
        { title: "Hirsutism", link: "/page/hirsutism" },
        { title: "Hyperandrogenism ", link: "/page/hyperandrogenism" },
        {
          title: "Premature Ovarian Insufficiency	",
          link: "/page/premature_ovarian_insufficiency",
        },
        {
          title: "Male Erectile Dysfunction ",
          link: "/page/male_erectile_dysfunction",
        },
        { title: "Menopause ", link: "/page/menopause" },
        { title: "Precocious Puberty", link: "/page/precocious_puberty" },
        { title: "Delayed Puberty", link: "/page/delayed_puberty" },
      ],
    },
    {
      navitem: "Vitamin D & Calcium",
      submenu: [
        { title: "Vitamin D Deficiency", link: "/page/vitamin_d_deficiency" },
        { title: "Rickets", link: "/page/Rickets" },
        { title: "Osteomalacia", link: "/page/osteomalacia" },
        { title: "Hypercalcemia ", link: "/page/hypercalcemia" },
        { title: "Hyperparathyroidism ", link: "/page/hyperparathyroidism" },
        { title: "Hypocalcemia", link: "/page/hypocalcemia" },
      ],
    },

    {
      navitem: "Growth",
      submenu: [
        { title: "Short Stature", link: "/page/short_stature" },
        { title: "Tall Stature", link: "/page/tall_stature" },
        { title: "Syndromic Short Stature", link: "/page/syndrome_short" },
      ],
    },

    {
      navitem: "Obesity",
      submenu: [
        { title: "Over Weight", link: "/page/overweight" },
        { title: "Obesity", link: "/page/obesity" },
      ],
    },
  ];

  const location = useLocation();
  const [routeMatched, setRouteMatched] = useState(false);

  useEffect(() => {
    const currentRoute = location.pathname;

    // Check if the current route matches and remove CSS if it does
    if (currentRoute === "/Gallery") {
      setRouteMatched(true);
    } else {
      // Add CSS if the current route does not match
      setRouteMatched(false);
    }
  }, [location]);

  return (
    <div className="App">
      <Header />
      {isMobile ? (
        <>
          <div id="mySidebar" className="sidebar">
            <a
              href="javascript:void(0)"
              className="closebtn"
              onClick={closeNav}
            >
              ×
            </a>
            <ul className="ul-nav1">
              <li className="nav-link">
                <Link to="/">Home</Link>
              </li>

              {/* lap */}
              {menu.map((element, index) => {
                return (
                  <li className="nav-link">
                    <a href="#">
                      {element.navitem}
                      <i className="fas fa-caret-down" />
                    </a>
                    <div className="dropdown">
                      <ul className="ul-nav2">
                        {element.submenu.map((subelement, subindex) => {
                          return (
                            <li className="dropdown-link">
                              <Link to={subelement.link}>
                                {subelement.title}
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </li>
                );
              })}

              {/* patient guide */}

              <li className="nav-link">
                <Link to="/Reviews">Reviews</Link>
              </li>

              {/* About us */}
              <li className="nav-link">
                <a href="">
                  About us
                  <i className="fas fa-caret-down" />
                </a>
                <div className="dropdown">
                  <ul className="ul-nav2">
                    <li className="dropdown-link">
                      <Link to="/Aboutdr">About Dr. Padmanabha Varma</Link>
                    </li>
                    <li className="dropdown-link">
                      <Link to="/Aboutclinic">About Clinic</Link>
                    </li>

                    <li className="dropdown-link">
                      <Link to="/Contact">Contact us</Link>
                    </li>
                  </ul>
                </div>
              </li>

              <li className="nav-link">
                <Link to="/Gallery">Gallery</Link>
              </li>
            </ul>
          </div>
          <div id="main1">
            <button className="openbtn" onClick={openNav}>
              ☰ Dr. Padmanabha Varma's Hormone Clinic
            </button>
          </div>
        </>
      ) : (
        <div className="headerSEct">
          <div className={routeMatched ? "headerGallery" : "headerHome"}>
            <div className="">
              <div className="nav-btn">
                <div className="nav-links">
                  <ul className="ul-nav1">
                    <li className="nav-link">
                      <Link to="/">
                        <i
                          className="fa fa-home"
                          style={{ fontSize: "20px" }}
                        ></i>
                      </Link>
                    </li>

                    {/* lap */}
                    {menu.map((element, index) => {
                      return (
                        <li className="nav-link">
                          <a href="#">
                            {element.navitem}
                            <i className="fas fa-caret-down" />
                          </a>
                          <div className="dropdown">
                            <ul className="ul-nav2">
                              {element.submenu.map((subelement, subindex) => {
                                return (
                                  <li className="dropdown-link">
                                    <Link to={subelement.link}>
                                      {subelement.title}
                                    </Link>
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        </li>
                      );
                    })}

                    {/* end lap */}

                    {/* patient guide */}
                    <li className="nav-link">
                      <Link to="/Reviews">Reviews</Link>
                    </li>

                    {/* About us */}
                    <li className="nav-link">
                      <a href="">
                        About us
                        <i className="fas fa-caret-down" />
                      </a>
                      <div className="dropdown">
                        <ul className="ul-nav2">
                          <li className="dropdown-link">
                            <Link to="/Aboutdr">
                              About Dr. Padmanabha Varma
                            </Link>
                          </li>
                          <li className="dropdown-link">
                            <Link to="/Aboutclinic">About Clinic</Link>
                          </li>

                          <li className="dropdown-link">
                            <Link to="/Contact">Contact us</Link>
                          </li>
                        </ul>
                      </div>
                    </li>

                    <li className="nav-link">
                      <Link to="/Gallery">Gallery</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/Reviews" element={<Reviews />} />
        <Route path="/Aboutdr" element={<Aboutdr />} />
        <Route path="/Gallery" element={<ImageLightbox />} />
        <Route path="/Aboutclinic" element={<Aboutclinic />} />
        <Route path="/page/:pagename" element={<Dynamic_page />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
